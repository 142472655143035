import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const StyledServiceCard = styled.div`
  .services-card-link {
    text-decoration: none;
  }
  .services-card {
    width: 100%;
    max-width: 500px;
    text-align: center;
    display: grid;
    align-items: center;
    justify-content: center;
    .image-wrapper {
      grid-area: 1/1;
      display: grid;
      align-items: center;
      justify-content: center;
      box-shadow: none;
      will-change: transform, box-shadow;
      transition: box-shadow 0.6s cubic-bezier(0.43, 0.13, 0.23, 0.96);
      overflow: hidden;
      &:hover {
        box-shadow: 0.2em 0.2em 0.4em 0.2em rgba(0, 0, 0, 50%);
        transition: box-shadow 0.6s cubic-bezier(0.43, 0.13, 0.23, 0.96);

        .background-image {
          transform: scale(1.1);
        }
        .background-filter {
          transform: scale(1.1);
        }
      }
    }
    .background-image {
      object-fit: cover;
      grid-area: 1/1;
      height: 100%;
      width: auto;
      will-change: transform;
      transform: scale(1);
      transition: transform 0.6s cubic-bezier(0.43, 0.13, 0.23, 0.96);
    }
    .background-filter {
      position: relative;
      grid-area: 1/1;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      will-change: transform;
      transform: scale(1);
      transition: transform 0.6s cubic-bezier(0.43, 0.13, 0.23, 0.96);
    }
    h3 {
      position: relative;
      grid-area: 1/1;
      color: var(--white);
    }
  }
`;

const itemVariants = {
  offscreen: {
    y: 300,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.19, 1, 0.22, 1],
      duration: 1.5,
    },
  },
};

const MotionServiceCard = motion(StyledServiceCard);

export default function ContentCard({
  cardData: { title, url, linkTitle, image, alt },
}) {
  return (
    <MotionServiceCard variants={itemVariants}>
      <Link className="services-card-link" to={url} title={linkTitle}>
        <div className="services-card">
          <div className="image-wrapper">
            <img className="background-image" src={image} alt={alt} />
            <div className="background-filter" />
          </div>
          <h3>{title}</h3>
        </div>
      </Link>
    </MotionServiceCard>
  );
}
