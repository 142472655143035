import React from 'react';
import styled from 'styled-components';
import ContentCard from './ContentCard';
import commercial from '../images/commercial.jpg';
import residential from '../images/residential.jpg';
import customFabrication from '../images/custom-fabrication.jpg';
import { motion } from 'framer-motion';

const StyledContent = styled.section`
  background: linear-gradient(var(--charcoal), var(--black));
  .content-wrapper {
    width: 92vw;
    max-width: 1445px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    grid-area: 1/1;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 75px;
    padding-bottom: 75px;
    @media (min-width: 1024px) {
      padding-top: 90px;
      padding-bottom: 100px;
    }

    .text-section {
      justify-self: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 500px;
      padding-bottom: 40px;
      color: var(--white);
      @media (min-width: 1024px) {
        width: 90%;
      }
    }
    .services-section {
      display: grid;
      align-items: center;
      justify-items: center;
      grid-template-columns: repeat(1, 1fr);
      gap: 15px;
      @media (min-width: 1024px) {
        gap: 30px;
        grid-template-columns: repeat(3, 1fr);
        grid-area: 2 / 2/ 2 / 8;
      }
    }
  }
`;

export default function ContentSection({ contentData }) {
  const cardData = [
    {
      title: 'Commercial',
      url: '/commercial/',
      linkTitle: 'View Commercial Service Page',
      image: commercial,
      alt: 'Commercial Architectural Sheet Metal Example',
    },
    {
      title: 'Custom Fabrication',
      url: '/fabrication/',
      linkTitle: 'View Custom Fabrication Service Page',
      image: customFabrication,
      alt: 'Custom Fabrication Architectural Sheet Metal Example',
    },
    {
      title: 'Residential',
      url: '/residential/',
      linkTitle: 'View Residential Service Page',
      image: residential,
      alt: 'Residential Architectural Sheet Metal Example',
    },
  ];

  const itemVariants = {
    offscreen: {
      y: 300,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        ease: [0.19, 1, 0.22, 1],
        duration: 1.5,
      },
    },
  };

  const cards = cardData.map((card) => (
    <ContentCard key={card.title} cardData={card} />
  ));

  return (
    <StyledContent>
      <motion.div className="content-wrapper">
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.2 }}
          transition={{ staggerChildren: 0.15 }}
          className="text-section"
        >
          <motion.h2 variants={itemVariants}>
            {contentData.contentHeading}
          </motion.h2>
          <motion.p variants={itemVariants}>
            {contentData.contentsubHeading}
          </motion.p>
        </motion.div>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.2 }}
          transition={{ staggerChildren: 0.15 }}
          className="services-section"
        >
          {cards}
        </motion.div>
      </motion.div>
    </StyledContent>
  );
}
